<template>
    <div class="screen">
        <!-- <van-nav-bar title="筛选" left-text="返回" left-arrow @click-left="onClickLeft" /> -->
        <div class="item">
            <div class="center">
                <!-- 招聘状态 -->
                <div class="tags_list">
                    <div class="left">
                        <div class="must"></div>
                        <div class="title">招聘状态</div>
                    </div>
                    <div class="other">
                        <div v-for="item in screen.filter_job_status_array" :key="item.index" @click="selectMore('filter_job_status',item.key)" :class="selectClass(screenValue.filter_job_status,item.key) ? 'select' : ''">{{item.value}}</div>
                    </div>
                </div>
                <!-- 标签里的内容 -->
                <div class="tags_list" v-for="(item,index) in screen.filter_tag_list_array" :key="index">
                    <div class="left" v-if="item.list.length">
                        <div class="must"></div>
                        <div class="title">{{item.name}}</div>
                    </div>
                    <div class="other" v-if="item.list.length">
                        <div v-for="tag in item.list" :key="tag.index" @click="selectTag(index,tag.tag_id)" :class="selectClass(screenValue['filter_tag_list['+index+']'],tag.tag_id) ? 'select' : ''">
                            {{tag.tag_name}}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <div class="footer-bottom"></div>
        <div class="footer">
            <div class="reset button" @click="reset">重置</div>
            <div class="confirm button" @click="confirm">确认</div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import '@/assets/css/index.css'
export default {
    data() {
        return {
            screen: {}, // 筛选的列表  不会改变的
            screenValue: {}, // 筛选的值   会改变的
            requestSuccess: true
        }
    },
    mounted() {
        this.$store.commit("hideTabbar");
        if (Object.keys(this.$store.state.screen).length) {
            this.screenValue = this.$store.state.screenValue
            this.screen = this.$store.state.screen
        } else {
            this.getScreen()
        }
    },
    methods: {
        // 确认
        confirm() {
            let iii = -1;
            for (let i = 0; i < this.screen.filter_tag_list_array.length; i++) {
                if (this.screen.filter_tag_list_array[i].tag_type == 'customize_tag') {
                    iii = i
                }
            }
            this.$store.state.screenValue = this.screenValue
            this.$store.state.screen = this.screen
            this.$store.state.iszidingy = iii
            this.$store.state.refreshList = 1
            this.$parent.hideMask();
            this.$store.commit("showTabbar");
            this.$parent.getList(1);
        },
        // 重置
        reset() {
            let iii = -1;
            for (let i = 0; i < this.screen.filter_tag_list_array.length; i++) {
                if (this.screen.filter_tag_list_array[i].tag_type == 'customize_tag') {
                    iii = i
                }
            }
            this.$store.state.iszidingy = iii
            Object.keys(this.screenValue).forEach(key => {
                if (key == 'filter_tag_list') {
                    this.screenValue.filter_tag_list.filter((v, i) => {
                        this.screenValue['filter_tag_list[' + i + ']'] = [-1]
                    })
                } else {
                    this.screenValue[key] = [-1]
                }
            })
            this.$store.state.screenValue = this.screenValue
            this.$store.state.screen = this.screen
            this.$store.state.refreshList = 1
            this.$parent.getList(1);
        },
        selectClass(array = [], key) {
            var index = array.indexOf(key);
            if (index > -1) {
                return true
            } else { //没有选中
                return false
            }
        },
        // 多选
        selectMore(type, value) {
            var array = this.screenValue[type]//如客户来源数组：source
            if (value == -1) { //选择了不限
                array = [-1]
            } else {
                if (array.indexOf(-1) > -1) { //如果数组里面有-1则需要清空
                    array = []
                }
                var index = array.indexOf(value)
                if (index > -1) { //数组里存在这个值
                    array.splice(index, 1)
                    if (array.length <= 0) {
                        array.push(-1)
                    }
                } else {
                    array.push(value)
                }
            }
            Vue.set(this.screenValue, type, array)
        },
        nomove() { },
        // 多选标签
        selectTag(type, value) {
            var array = this.screenValue['filter_tag_list[' + type + ']']
            if (value == -1) { //选择了不限
                array = [-1]
            } else {
                if (array.indexOf(-1) > -1) { //如果数组里面有-1则需要清空
                    array = []
                }
                var index = array.indexOf(value)
                if (index > -1) { //数组里存在这个值
                    array.splice(index, 1)
                    if (array.length <= 0) {
                        array.push(-1)
                    }
                } else {
                    array.push(value)
                }
            }
            Vue.set(this.screenValue, ["filter_tag_list[" + type + ']'], array)
            this.screenValue = { ...this.screenValue }
        },
        // 获取学历，工作经验等下拉框
        getScreen() {
            this.Request('recruitment/member_recruitment/category', 'get', { type: 'all', member_id: localStorage.getItem('member_id') }).then(res => {
                if (res.status == 0) {
                    Object.keys(res.data).forEach(key => {
                        if (res.data[key].length) {
                            // 标签
                            if (key == 'filter_tag_list') {
                                res.data.filter_tag_list.filter((value, index) => {
                                    if (value.list.length) {
                                        let data = {
                                            'tag_id': -1,
                                            'tag_name': '不限',
                                            'tag_status': 0
                                        }
                                        value.list.splice(0, 0, data);
                                        this.screenValue["filter_tag_list[" + index + ']'] = [-1]
                                    }

                                })
                            } else if (key == 'filter_job_status') { // 招聘状态
                                res.data.filter_job_status.filter((v, i) => {
                                    if (v.key == 10) {
                                        res.data.filter_job_status.splice(i, 1);
                                        return
                                    }
                                })
                                let data = {
                                    'key': -1,
                                    'value': '不限'
                                }
                                res.data[key].splice(0, 0, data);

                            } else { // 其他
                                let data = {
                                    'key': -1,
                                    'value': '不限'
                                }
                                res.data[key].splice(0, 0, data);

                            }
                        }
                        Vue.set(this.screen, key + '_array', res.data[key])
                        Vue.set(this.screenValue, key, [-1])
                    })
                }
            })
        },
    },
}
</script>
<style scoped>
/* .screen { */
/* height: calc(100% - 70px); */
/* overflow: auto; */
/* } */
.item {
    padding: 0px 0px 30px 0;
}
/* 筛选里的标签 */
.center .tags_list {
    padding-top: 50px;
}
.center .tags_list:first-of-type {
    padding: 0;
}
.center .tags_list .left {
    display: flex;
    width: 40%;
    line-height: 46px;
    font-weight: 700;
    padding-top: 40px;
    margin-left: 30px;
}
.center .tags_list .left:first-of-type {
    padding-top: 0;
}
.center .tags_list .other {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 10px;
    margin-right: 30px;
}
.center .tags_list .other div {
    background-color: #f5f5f5;
    min-width: 132px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    color: #333;
    font-size: 24px;
    border: 1px solid #f5f5f5;
    margin-top: 16px;
    padding: 0 10px;
    border-radius: 4px;
    margin-left: 20px;
}
.center .tags_list .other div.select {
    color: #0abbb9;
    background-color: #eafcfc;
    border-color: #0abbb9;
}
.footer div {
    border-radius: 6px;
}
.footer .reset {
    width: 35%;
    background-color: #f5f5f5;
    color: #333;
    margin-right: 30px;
}
.footer .confirm {
    width: 65%;
    margin-left: 0;
}
</style>
